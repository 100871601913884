<template>

  <div class="border-top border-primary m-2 p-3">
    <div class="row">
      <h2 class="par mb-2">
        {{ companyname }}
      </h2>
    </div>
    <div class="row">
      <div class="col-md-2 col-5 mb-2 mb-md-0 mx-auto">
        <div class="row">
          <a v-bind:href="companylink">
            <img v-bind:src="imgname" class="img-fluid">
          </a>
        </div>
      </div>
      <div class="col-md-10 col-12 mt-2 pl-md-3">
        <p class="par mb-2">
          <slot name="text"></slot>
        </p>
        <p class="text-center text-md-left">
            <a v-bind:href="companylink">
                <button class="btn btn-primary">Vai al sito</button>
            </a>    
        </p>
        <p class="par"><i>
            <slot name="notes"></slot>
        </i></p>
      </div>
    </div>
    
  </div>

</template>

<script>
  export default {
    name: 'box',
    props: {
      imgname: {
        type: String,
        required: true
      },
      companyname: {
        type: String,
        required: true
      },
      companylink: {
        type: String,
        required: true
      }
    },
    methods: {
    },
    mounted() {
      if (!this.$slots.text) {
        console.error('Lo slot "text" è obbligatorio!');
      }
      if (!this.$slots.notes) {
        console.error('Lo slot "notes" è obbligatorio!');
      }
    }
  }
</script>

<style scoped>
</style>