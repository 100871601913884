<template>
<div v-if="show" style="display:block" class="modal fade" v-bind:class="{'show': show}" id="notifiche" tabindex="-1" role="dialog" aria-labelledby="uploadDocument">
  <div class="modal-dialog">
    <div class="modal-content modal-md">
      <div class="modal-header">
        <h4 class="modal-title"> {{$t('NotifyTitle')}}</h4>
        <button type="button" class="close" aria-label="Close" v-on:click="$emit('cancel')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="btn btn-light btn-lg btn-block" style="border-radius:10px;border: 1px solid #ccc;" v-for="n in notifichetxt" v-bind:key="n.txt" type="button" v-on:click="goto(n.link)"> 
       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell-fill" viewBox="0 0 16 16" style="float:left;">
            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"></path>
         </svg>
        <span> 
              <p>
              {{$t(n.txt)}}
              <br/>
              <i>{{$t(n.istruzioni)}}</i>
            </p>
        </span>
       
        </div>
        
        
      </div>
      
     <!-- <div class="modal-footer">
        <button type="button" class="btn btn-primary" v-on:click="$emit('cancel')">{{$t('Ok')}}</button>
      </div> -->
    </div>
  </div>
</div>

</template>

<script>
  import apiclient from '@/apiclient.js'

  export default {
    name: 'modalnotify',
    props: {
      show: Boolean,
      id: String,
      notifichetxt: Array
    },
    data: function () {
      return {
        submitOk: false,
        myfile: {},
        fileData: '',
        progress: 0,
        uploading: false
      }
    },
    computed: {
      erroredimensioni: function() {
        if (!this.myfile) return false;
        if (this.myfile.size<=5242880) return false;
        return true;
      },
    },
    methods: {
      fileUpload: function() {
        if (this.$refs.file.files.length>0) {
          this.submitOk=true;
          this.myfile=this.$refs.file.files[0];
          console.log(this.myfile);

          const reader = new FileReader();
          reader.onload = this.doReadFile
          reader.readAsDataURL(this.myfile);

        }
      },
      uploadProgress: function(ev) {
        console.log('uploadProgress');
        console.log(ev);
        this.progress = ev.loaded / ev.total * 100;        
      },
      doReadFile: function (e) {
        console.log('doReadFile');
        console.log(e);
        this.fileData=e.target.result.split(',')[1];
      },
      doUpload: function() {
        this.uploading=true;
        var self=this;
        this.$nextTick(function () {
          self.$store.dispatch('loginbyid').then(function() {
            console.log('doUpload');
            var data={ username: self.$store.state.connection.username, callname:'uploadDocument', crypt: self.$store.state.connection.userdata,
              parentId: self.id,  filename: self.myfile.name,  payload: self.fileData,
            };
            apiclient.getcandidatura( self.$store.state.connection, data, self.uploadProgress).then( function(resp) {
              console.log('doUpload:');
              console.log(resp)
              self.$emit('submit', resp);
              self.myfile={};
              self.fileData='';
              self.progress=0;
              self.uploading=false;
            });
          });
        });
      },
      goto: function(where){
        this.$router.push({name: where});
      }
    }
  }
</script>

<style scoped>
</style>