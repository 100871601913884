export default {
    "subtitleCandidatura": "申请区域",
    "ATTENZIONE": "请注意！",
    "caroUtente": "尊敬的用户",
    "avvisoInizioProcessoCandidatura": "在开始申请流程之前，请确保拥有 PDF 格式的简历",
    "SCARICAMODELLOCV": "下载简历模版",
    "INIZIA": "开始",
    "DatiPersonali": "个人信息",
    "Nome": "名",
    "Cognome": "姓",
    "Sesso": "性别",
    "F": "女",
    "M": "男",
    "Telefono": "座机",
    "Cellulare": "手机",
    "AltroCellulare": "备用手机",
    "E-Mail": "邮箱",
    "MailSecondaria": "备用邮箱",
    "Conferma Email": "邮箱确认",
    "Successivo": "下一页",
    "Indietro": "返回",
    "Anagrafica": "身份信息",
    "DatadiNascita": "出身日期",
    "CodiceFiscale": "税号",
    "haiCF": "是否拥有意大利税号？",
    "CittàdiNascita": "出身地",
    "Città": "出身城市",
    "ProvinciadiNascita": "出身省份",
    "Scegliunaopzione": "请选择",
    "NazionediNascita": "出生国家",
    "IndirizzoResidenza": "居住地址",
    "CittàdiResidenza": "居住城市",
    "CAP": "邮编",
    "ProvinciadiResidenza": "居住省份",
    "NazionediResidenza": "居住国家",
    "Cittadinanza": "国籍",
    "SecondaCittadinanza": "另有国籍",
    "Studi": "学历",
    "QualificaoTitolodiStudio": "学历或学位",
    "IndirizzodiStudio": "所学专业",
    "HotelManagementCulinaryArtsDegree": "酒店管理和厨艺专业",
    "Si": "是",
    "No": "否",
    "Altro": "其它",
    "Annoconseguimentoqualifica": "获得资格的年份",
    "NazioneIstituto": "学校所在国家",
    "CittàIstituto": "学校所在城市",
    "NomeIstituto": "学校名称",
    "FrequentatoAlma": "在ALMA之前上过其它烹饪学校吗？",
    "AltraScuolaCucina": "是哪一个烹饪学校？",
    "ConosciutoAlma": "你是怎么知道ALMA的?",
    "FilecaricareLabel": "建议使用 PDF 格式，大小不超过 4.5 MB",
    "Dimensioni": "文件大小",
    "DimensioniEccessive": "文件超过了最大允许限制，即使限制是指示性的，也请减小文件规模",
    "InserireCurriculum": "输入简历",
    "CaricaCurriculum": "上传简历",
    "Documento di riconoscimento": "身份证明文件",
    "TipoDocumento": "身份证种类",
    "Numero": "身份证号",
    "DataRilascio": "发放日期",
    "DataScadenza": "有效期至",
    "NazioneRilascio": "发放国家",
    "CaricaDocumentoIdentita": "上传身份证件",
    "FilecaricareDocLabel": "建议使用 PDF或JPEG 格式，大小不超过 4.5 MB",
    "Letuecredenziali": "密码设置",
    "TestoRegistrazione": "请输入邮箱作为用户名，并设置密码以注册你的个人区域，然后继续申请",
    "ConfermaPassword": "确认密码",
    "ModificaPasswordLabel": "如果忘记了密码，可以在完成申请后在个人区域修改密码",
    "Fine": "完成！",

    // titolo di studio
    "Altro (specificare)": "其它 （请指明）",
    "Istituto Alberghiero": "酒店或烹饪艺术高中学生",

    // conosciuto alma
    "Ho partecipato ad un corso ALMA in passato": "我曾经参加过 ALMA 的课程",
    "Seguo ALMA sui social": "我在社交网络上关注 ALMA",
    "ALMA ha visitato la scuola che sto frequentando": "ALMA 曾访问过我所在的学校",

    // DOCUMENTI
    "Carta d'identità": "身份证 ",
    "Passaporto": "护照",

    // LIVELLO INGLESE
    "labelLivelloInglese": "在此声明我的英语水平是",
    "Madrelingua": "母语",
    "A1/A2": "A1/A2",
    "B1/B2": "B1/B2",
    "C1/C2": "C1/C2",
    "CommonEuropeanFramework": "根据欧共体同一语言水平衡量框架",
    "labelUploadEnglishCertificate": "如果你有英语语言证书，请在这里上传",
    "englishTestAlert": "请注意，你可能会根据招生部门的决定参加英语水平测试",

    "USERNAME": "用户名",
    "PASSWORD": "密码设置",

    //INFO PRIVACY
    "letto_e_accetto": "我已阅读并接受",
    "InformativaPrivacy": "隐私信息",
    "comunicazioniPromozionali": "用于促销和宣传，可在ALMA网站、Facebook和YouTube上使用与烹饪学院、活动、课程相关的图片和视频，以及所有适合代表学院形象的其它多媒体工具。也可用于营销，例如发送关于学院（如新闻通讯、促销通讯、课程、开放日、活动、服务等）及合作伙伴举行的可引发用户兴趣的各种活动信息，并可通过自动化方式（如电子邮件、短信）发送。",

    // NAZIONI - NAZIONALITà
    "Afghanistan": "Afghanistan",
    "Albania": "Albania",
    "Algeria": "Algeria",
    "Samoa Americane": "American Samoa",
    "Andorra": "Andorra",
    "Angola (compresa Cabinda)": "Angola",
    "Antigua E Barbuda": "Antigua and Barbuda",
    "Armenia": "Armenia",
    "Argentina": "Argentina",
    "Australia": "Australia",
    "Austria": "Austria",
    "Azerbaigian": "Azerbaijan",
    "Bahamas": "Bahamas",
    "Bahrein": "Bahrain",
    "Bangladesh": "Bangladesh",
    "Barbados": "Barbados",
    "Belarus": "Belarus",
    "Belgio": "Belgium",
    "Belize": "Belize",
    "Bhutan": "Bhutan",
    "Bolivia": "Bolivia",
    "Bosnia-erzegovina": "Bosnia and Herzegowina",
    "Botswana": "Botswana",
    "Brasile": "Brazil",
    "Brunei": "Brunei Darussalam",
    "Bulgaria": "Bulgaria",
    "Burkina-faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cambogia": "Cambodia",
    "Camerun": "Cameroon",
    "Canada": "Canada",
    "Capo Verde": "Cape Verde",
    "Repubblica Centrafricana": "Central African Republic",
    "Ciad": "Chad",
    "Cile": "Chile",
    "Cina": "China",
    "Colombia": "Colombia",
    "Comore": "Comoros",
    "Corea Del Sud": "South Korea",
    "Costarica": "Costa Rica",
    "Costa D'avorio": "Cote D'Ivoire",
    "Croazia": "Croatia",
    "Cuba": "Cuba",
    "Cipro": "Cyprus",
    "Repubblica Ceca": "Czech Republic",
    "Danimarca": "Denmark",
    "Dominica": "Dominica",
    "Repubblica Dominicana": "Dominican Republic",
    "Ecuador": "Ecuador",
    "Egitto": "Egypt",
    "El Salvador": "El Salvador",
    "Guinea Equatoriale": "Equatorial Guinea",
    "Eritrea": "Eritrea",
    "Estonia": "Estonia",
    "Etiopia": "Ethiopia",
    "Figi": "Fiji",
    "Finlandia": "Finland",
    "Francia (compreso Monaco E Dipartimenti Oltemare)": "France",
    "Gabon": "Gabon",
    "Gambia": "Gambia",
    "Georgia": "Georgia",
    "Germania": "Germany",
    "Ghana": "Ghana",
    "Grecia": "Greece",
    "Grenada": "Grenada",
    "Guatemala": "Guatemala",
    "Guinea": "Guinea",
    "Guinea Bissau": "Guinea-bissau",
    "Guyana": "Guyana",
    "Haiti": "Haiti",
    "Honduras (compreso Isole Swan)": "Honduras",
    "Hong Kong": "Hong Kong",
    "Ungheria": "Hungary",
    "Islanda": "Iceland",
    "India": "India",
    "Indonesia": "Indonesia",
    "Iran": "Iran (Islamic Republic of)",
    "Iraq": "Iraq",
    "Irlanda": "Ireland",
    "Israele": "Israel",
    "Italia": "Italy",
    "Giamaica": "Jamaica",
    "Giappone": "Japan",
    "Giordania": "Jordan",
    "Kazakistan": "Kazakhstan",
    "Kenya": "Kenya",
    "Kiribati": "Kiribati",
    "Kuwait": "Kuwait",
    "Kirghizistan": "Kyrgyzstan",
    "Laos": "Lao People's Democratic Republic",
    "Lettonia": "Latvia",
    "Libano": "Lebanon",
    "Lesotho": "Lesotho",
    "Liberia": "Liberia",
    "Libia": "Libyan Arab Jamahiriya",
    "Liechtenstein": "Liechtenstein",
    "Lituania": "Lithuania",
    "Lussemburgo": "Luxembourg",
    "Macao": "Macau",
    "Macedonia, the former Yugoslav Republ. Of": "Macedonia, The Former Yugoslav Republic of",
    "Madagascar": "Madagascar",
    "Malawi": "Malawi",
    "Malaysia Peninsulare Ed Orientale": "Malaysia",
    "Maldive": "Maldives",
    "Mali": "Mali",
    "Malta (gozo, Comino)": "Malta",
    "Isole Marshall": "Marshall Islands",
    "Mauritania": "Mauritania",
    "Maurizio Isola": "Mauritius",
    "Messico": "Mexico",
    "Federazione Degli Stati Di Micronesia": "Micronesia, Federated States of",
    "Moldavia": "Moldova, Republic of",
    "Monaco": "Monaco",
    "Mongolia": "Mongolia",
    "Marocco": "Morocco",
    "Mozambico": "Mozambique",
    "Myanmar": "Myanmar",
    "Namibia": "Namibia",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Antille Olandesi": "Netherlands",
    "Nuova Zelanda": "New Zealand",
    "Nicaragua (compreso Isole Corn)": "Nicaragua",
    "Niger": "Niger",
    "Nigeria": "Nigeria",
    "Norvegia (compresa Jan Mayen;escluso Arc.svalbard)": "Norway",
    "Oman": "Oman",
    "Pakistan": "Pakistan",
    "PALAU": "Palau",
    "Panama": "Panama",
    "Papuasia - Nuova Guinea": "Papua New Guinea",
    "Paraguay": "Paraguay",
    "Peru'": "Peru",
    "Filippine": "Philippines",
    "Polonia": "Poland",
    "Portogallo": "Portugal",
    "Qatar": "Qatar",
    "Romania": "Romania",
    "Russia": "Russian Federation",
    "Ruanda": "Rwanda",
    "San Cristoforo E Nevis": "Saint Kitts and Nevis",
    "Saint Lucia": "Saint Lucia",
    "Saint Vincent": "Saint Vincent and the Grenadines",
    "Samoa Occidentali": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome' E Principe": "Sao Tome and Principe",
    "Arabia Saudita": "Saudi Arabia",
    "Senegal": "Senegal",
    "Seychelles E Dipendenze": "Seychelles",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapore",
    "Slovacchia": "Slovakia (Slovak Republic)",
    "Slovenia": "Slovenia",
    "Isole Salomone": "Solomon Islands",
    "Somalia": "Somalia",
    "Sud Africa": "South Africa",
    "Spagna (compr.baleari,canarie,ceuta,melilla)": "Spain",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Sudan",
    "Suriname": "Suriname",
    "Svezia": "Sweden",
    "Svizzera": "Switzerland",
    "Siria": "Syrian Arab Republic",
    "Taiwan": "Taiwan",
    "Tanzania (tanganica, Zanzibar, Penba)": "Tanzania, United Republic of",
    "Thailandia": "Thailand",
    "Togo": "Togo",
    "Tonga": "Tonga",
    "Trinidad E Tobago": "Trinidad and Tobago",
    "Tunisia": "Tunisia",
    "Turchia": "Turkey",
    "Turkmenistan": "Turkmenistan",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ucraina": "Ukraine",
    "Emirati Arabi Uniti": "United Arab Emirates",
    "Regno Unito (Gran Bretagna, Irlanda Nord)": "United Kingdom",
    "Stati Uniti d'America": "United States",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Uzbekistan",
    "Vanuatu": "Vanuatu",
    "Citta' Del Vaticano": "Vatican City State (Holy See)",
    "Venezuela": "Venezuela",
    "Vietnam": "Viet Nam",
    "Yemen": "Yemen",
    "Serbia": "Serbia",
    "Congo (repubblica)": "The Democratic Republic of Congo (ex Zaire)",
    "Zambia": "Zambia",
    "Zimbabwe": "Zimbabwe",
    "Montenegro": "Montenegro",
    "Palestinian Territory, Occupied": "Territori dell'Autonomia Palestinese",
    "Timor-Leste": "Timor Orientale",
    "Repub. Federale Di Jugoslavia (serbia,montenegro)": "Jugoslavia (Serbia-Montenegro)",
    "Apolide": "Apolide",

}