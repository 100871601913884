<template>
    <div class="home">
        <navbar :menulist="$store.state.mainmenu" />
        
        <h2 class="welcome">Coupons</h2>
        <div class="col-xs-12 col-sm-12">
            <coupon companyname="Errea Sport Spa" companylink="https://www.errea.com/" imgname="img/errea.jpg">
                <template #text>
                    Usa il coupon: <b>"ALMA20"</b> per il 20% di sconto sul sito!
                </template>
                <template #notes>
                    Inserire il codice in fase di acquisto, sconto non cumulabile con altri sconti già in essere.
                </template>
            </coupon>
            <coupon v-if="isBeforeApril30th2025" companyname="illyCaffè spa" companylink="https://www.illy.com/it-it" imgname="img/illy.jpg">
                <template #text>
                    Sconto del 15% sul sito illy.com inserendo il codice <b>"ILLYFORALMA"</b>.
                </template>
                <template #notes>
                    Promo valida dal 15 gennaio al 30 aprile 2025.<br/>
                    Sono escluse dalla promozione le capsule di caffè e le macchine X▪CAPS, le capsule di caffe Iperespresso, le confezioni da 500g di caffè in grani, le illy Art Collection Biennale 2024, Barcolana e Genesi, non è cumulabile con altre offerte attive e con i vantaggi ILLY LOVERS. La spedizione è gratuita per tutti gli ordini superiori a 60€.
                </template>
            </coupon>
            <coupon companyname="Azienda Agricola Coppini Arte Olearia Srl" companylink="https://coppiniarteolearia.com/" imgname="img/coppini.jpg">
                <template #text>
                    Usa il coupon: <b>"ALMA"</b> per il 15% di sconto sul sito!
                </template>
                <template #notes>
                    Inserire il codice in fase di acquisto, sconto non cumulabile con altri sconti già in essere.
                </template>
            </coupon>
            
        </div>

        <footerline />
        
    </div>
</template>

<script>
    import navbar       from '@/components/navbar.vue'
    import footerline   from '@/components/footerline.vue'
    import coupon       from '@/components/coupon.vue'

    export default {
        name: 'EcommerceCoupons',
        components: {
            navbar, footerline, coupon
        },
        data: function() {
            return {
                img_illy: 'img/illy.jpg',
                img_errea: 'img/errea.jpg',
                img_coppini: 'img/coppini.jpg',
                currentDate: new Date(), // Data corrente
                apr30: new Date('2025-04-30'), // 30 aprile 2025
                classeRiga: 'row border-top border-primary m-2 p-3',
                classeImg: 'col-md-2 col-5 mb-2 mb-md-0 mx-auto',
                classeTesto: 'col-md-10 col-12 '
            }
        },
        computed: {
            isBeforeApril30th2025() {
                // Confronta la data corrente con il 30 aprile 2025
                return this.currentDate >= new Date('2025-01-15') && this.currentDate <= this.apr30;
            }
        },
        mounted: function() {
        },
        methods: {}
    }
</script>