<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <div v-if="!esamiIntermedi && !esamiFinali">
      <h2 class="welcome">{{$t('Esami')}}</h2>

      <div class="row">
        <div class="col-6 text-center">
          <button class="btn btn-primary" :disabled="!$store.state.opptyCorso.IsActiveTesiIntermedia__c" v-on:click="selectEsami('esamiIntermedi')">{{$t('EsameIntermedio')}}</button>
        </div>

        <div class="col-6 text-center">
          <button class="btn btn-primary" :disabled="!$store.state.opptyCorso.IsActiveTesi__c" v-on:click="selectEsami('esamiFinali')">{{$t('EsamiFinali')}}</button>
        </div>
      </div>
    </div>

    <tesi v-if="esamiIntermedi" :titolo="$t('EsameIntermedio')" :tipo="'Esame Intermedio'" :uploadDossier="false"></tesi>

    <tesi v-if="esamiFinali" :titolo="$t('Tesi')" :tipo="'Esame Finale'" :uploadDossier="true"></tesi>

    <footerline></footerline>

  </div>
</template>
  
<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'
  import footerline from '@/components/footerline.vue'
  import tesi from '@/components/tesi.vue'

  export default {
    name: 'Home',
    components: {
      navbar,
      herobig,
      tesi,
      footerline
    },
    data: function () {
      return {
        esame: '',
        esamiIntermedi: false,
        esamiFinali: false
      };
    },
    computed: {},
    mounted: function () {
      if(this.$store.state.opptyCorso.Edizione_Corso__r.N_tesi__c == 1 && this.$store.state.opptyCorso.IsActiveTesi__c) {
        this.selectEsami('esamiFinali')
      }
    },
    methods: {
      selectEsami(e){
        this.esame = e
        console.log('esame',e)
        if(e == 'esamiIntermedi') this.esamiIntermedi = true
        if(e == 'esamiFinali') this.esamiFinali = true
      }
    }
  }
</script>